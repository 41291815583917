.transparent-theme{
  &.card{
    background-color: rgba(0, 0, 0, .2);
    border-radius: 0.125rem;
    color: white;
    .card-header {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
}
