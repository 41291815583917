@mixin scrollbar( $colour1, $colour2) {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(linear, left top, right top, from($colour1), to($colour2));
    background-image: -webkit-linear-gradient(left, $colour1 0%, $colour2 100%);
    background-image: linear-gradient(to right, $colour1 0%, $colour2 100%);

    &.horizontal {
      background-image: -webkit-gradient(linear, left bottom, right bottom, from($colour1), to($colour2));
      background-image: -webkit-linear-gradient(right, $colour1 0%, $colour2 100%);
      background-image: linear-gradient(to right, $colour1 0%, $colour2 100%);
    }
  }
}

@mixin bordered($colour) {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border: 1px solid $colour;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none;
  }
}

.scrollbar-juicy-peach {@include scrollbar(#ffecd2, #fcb69f)}

.scrollbar-young-passion {@include scrollbar(#ff8177, #cf556c)}

.scrollbar-lady-lips { @include scrollbar(#ff9a9e, #fecfef)}

.scrollbar-sunny-morning { @include scrollbar(#f6d365, #fda085)}

.scrollbar-rainy-ashville { @include scrollbar(#fbc2eb, #a6c1ee)}

.scrollbar-frozen-dreams { @include scrollbar(#fdcbf1, #fdcbf1)}

.scrollbar-warm-flame { @include scrollbar(#ff9a9e, #fad0c4)}

.scrollbar-night-fade { @include scrollbar(#a18cd1, #fbc2eb)}

.scrollbar-spring-warmth { @include scrollbar(#fad0c4, #ffd1ff)}

.scrollbar-winter-neva { @include scrollbar(#a1c4fd, #c2e9fb)}

.scrollbar-dusty-grass { @include scrollbar(#d4fc79, #96e6a1)}

.scrollbar-tempting-azure { @include scrollbar(#84fab0, #8fd3f4)}

.scrollbar-heavy-rain { @include scrollbar(#cfd9df, #e2ebf0)}

.scrollbar-amy-crisp { @include scrollbar(#a6c0fe, #f68084)}

.scrollbar-mean-fruit { @include scrollbar(#fccb90, #d57eeb)}

.scrollbar-deep-blue { @include scrollbar(#e0c3fc, #8ec5fc)}

.scrollbar-ripe-malinka { @include scrollbar(#f093fb, #f5576c)}

.scrollbar-cloudy-knoxville { @include scrollbar(#fdfbfb, #ebedee)}

.scrollbar-morpheus-den { @include scrollbar(#30cfd0, #330867)}

.scrollbar-rare-wind { @include scrollbar(#a8edea, #fed6e3)}

.scrollbar-near-moon { @include scrollbar(#5ee7df, #b490ca)}

.scrollbar-deep-blue { @include scrollbar(#e0c3fc, #8ec5fc)}

.scrollbar-sea-grass { @include scrollbar(#65bc7b, #30cfd0)}

.scrollbar-pink { @include scrollbar(#ec407a, #ec407a)}

.scrollbar-indigo { @include scrollbar(#3f51b5, #3f51b5)}

.scrollbar-black { @include scrollbar(#000, #000)}

.bordered-pink {@include bordered(#ec407a)}

.bordered-indigo {@include bordered(#3f51b5)}

.bordered-black {@include bordered(#000)}

.square::-webkit-scrollbar-track {
  border-radius: 0 !important;
}

.square::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
}

.thin::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

.scrollbar-young-passion::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left top, right top, from(#ff8177), color-stop(0%, #ff867a), color-stop(21%, #ff8c7f), color-stop(52%, #f99185), color-stop(78%, #cf556c), to(#b12a5b));
  background-image: -webkit-linear-gradient(left, #ff8177 0%, #ff867a 0%, #ff8c7f 21%, #f99185 52%, #cf556c 78%, #b12a5b 100%);
  background-image: linear-gradient(to right, #ff8177 0%, #ff867a 0%, #ff8c7f 21%, #f99185 52%, #cf556c 78%, #b12a5b 100%);
}
