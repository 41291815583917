
$blur-less: 30px;
$blur: 100px;

.background{

  &:before{
    content: "";
    position: fixed;
    left: -5%;
    right: -5%;
    top: -5%;
    bottom: -5%;
    z-index: -1;

    display: block;
    -webkit-background-size: cover;
    background-size: cover;

    width: 110%;
    height: 110%;
    filter: blur(1rem);
  }

  &.blur-30:before{
    -webkit-filter: blur($blur-less);
    -ms-filter: blur($blur-less);
    filter: blur($blur-less) contrast(125%);
  }
  &.blur-100:before{
    -webkit-filter: blur($blur);
    -ms-filter: blur($blur);
    filter: blur($blur) contrast(125%);
  }

  &.greenery:before{
    background: url('/assets/covers/greenery.jpg') no-repeat center;
    -webkit-background-size: cover;
    background-size: cover
  }
}
