.custom-table {
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 0.5rem;

  .ngx-datatable.material {
    border-radius: 0;
    font-size: 12px;

    .datatable-header {
      .datatable-header-cell-label {
        color: #2a82a6;
        font-size: 14px;
        font-weight: normal;
        border-bottom: solid 1px #2a82a6;
        padding-bottom: 3px;
      }
    }
  }
}


.local-report {
  .mat-stroked-button {
    color: white;
    background: #2da5cb;
  }
}

span.mat-button-wrapper {
  span.download-icon {
    i.material-icons {
      color: white;
      display: inline-block;
      top: 2px;
      position: relative;
    }
  }
}

.reset-filter-btn {
  background: #2a82a6;
  color: white;

  i.material-icons {
    color: white;
    display: inline-block;
    left: 5px;
    top: -1px;
    position: relative;
  }

  &:hover {
    background: #2da5cb;
  }
}
