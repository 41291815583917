@mixin light-theme(){
  .theme-main-bg:before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    display: block;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgb(215, 215, 215)
  }

  .card {
    background: rgb(248, 248, 248);
    box-shadow: none;
  }

  .theme-text-dark {

  }

  .theme-text-light {

  }
}
