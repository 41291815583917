$danger: #ff4444;

.md-form {
  &.lb {
    input[type=text]:focus:not([readonly]), input[type=password]:focus:not([readonly]) {
      border-bottom: 1px solid #8EDEF8;
      box-shadow: 0 1px 0 0 #8EDEF8;
    }
    input[type=text]:focus:not([readonly]) + label, input[type=password]:focus:not([readonly]) + label {
      color: #8EDEF8;
    }
  }


  .form-control.ng-invalid:not(.ng-pristine) {
    &:not(:focus){
      border-color: $danger;
    }
  }

}


