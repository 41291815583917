
.btn-floating {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  width: 47px;
  height: 47px;
  padding: 0;
  border-radius: 50%;
  margin: 10px;
  background-color: #a6c;
  color: #fff;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);

  i {
    display: inline-block;
    width: inherit;
    color: #fff;
    font-size: 1.25rem;
    line-height: 47px;
    text-align: center;
  }

  &.btn-sm, &.btn-small {
    width: 36.15385px;
    height: 36.15385px;

    i {
      font-size: .96154rem;
      line-height: 36.15385px;
    }
  }

  &:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);

  }

}

.btn-rounded {
  border-radius: 10em !important;
}
