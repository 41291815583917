@import '~@angular/material/theming';
@include mat-core();

$primary-color: #4285f4 !default;
$primary: (
  50: #bad3fb,
  100: #a2c3fa,
  200: #8ab4f8,
  300: #72a4f7,
  400: #5a95f5,
  500: #4285f4,
  600: #2a75f3,
  700: #1266F1 ,
  800: #0D5BDD ,
  900: #0B51C5 ,
  A100: #42b2f4,
  A200: #4294f4,
  A400: #0e62ed,
  A700: #4259f4,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$primary-app-primary: mat-palette($primary);
$primary-app-accent:  mat-palette($primary, A200, A100, A400);

$primary-app-warn:    mat-palette($mat-red);

$primary-app-theme: mat-light-theme($primary-app-primary, $primary-app-accent, $primary-app-warn);

@include angular-material-theme($primary-app-theme);
