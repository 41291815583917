@mixin linearGradient($deg, $from, $to) {
  background: -webkit-linear-gradient(($deg + 10) + deg, $from, $to) !important;
  background: linear-gradient($deg + deg, $from, $to) !important;
}

@mixin beforeFullScreen() {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  display: block;
  -webkit-background-size: cover;
  background-size: cover;

  width: 100%;
  height: 100%;
  z-index: -1;
}

@mixin linearGradientBackground($rotation, $from, $to) {
  background: linear-gradient(to $rotation, $from, $to 100%);
  background: -webkit-gradient(linear, $rotation, from($from), to($to));
  background: -webkit-linear-gradient($rotation, $from, $to 100%);
}

.gradient-peach {
  @include linearGradient(40, #ffd86f, #fc6262);
}

.gradient-romantic {
  @include linearGradient(40, #ff6ec4, #7873f5);
}

.gradient-sky {
  @include linearGradient(40, #45cafc, #303f9f);
}

.gradient-azure-sky {
  @include linearGradient(40, #27a8d5, #2b6e8e);

  &.transparent-gradient {
    @include linearGradient(40, rgba(39, 168, 213, 0.7), rgba(43, 110, 142, 0.5));
  }
}

.gradient-sea {
  @include linearGradient(40, #2096ff, #05ffa3);
}

.gradient-grass {
  @include linearGradient(120, #00C851 20%, #96e6a1 100%);
}

.gradient-aqua {
  @include linearGradient(40, rgb(69, 202, 252), rgba(255, 255, 255, 0.8))
}

.gradient-sea-grass {
  @include linearGradient(40, #65bc7b, #30cfd0);
}

.gradient-dusty-grass {
  @include linearGradient(40, #d4fc79, #96e6a1)
}

.gradient-sky-transparent {
  background: linear-gradient(40deg, rgba(69, 202, 252, 0.6), rgba(48, 63, 159, 0.9)) !important
}

.gradient-bw-transparent {
  background: linear-gradient(40deg, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.1)) !important
}

.gradient-winter-neva {
  @include linearGradient(120, #a1c4fd, #c2e9fb);

  //background: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}

.full {
  $rotation: 98deg;

  $blue: rgb(22, 91, 231);
  $red: rgb(255, 32, 32);

  $darkblue: rgb(48, 63, 159);
  $pastelblue: rgb(69, 202, 252);

  $a-reg: 0.5;
  $a-light: 0.35;
  $a-vlight: 0.2;

  &.bg-gradient-rb {
    &.reg:before {
      @include linearGradientBackground($rotation, rgba($blue, $a-reg), rgba($red, $a-reg));
    }

    &.light:before {
      @include linearGradientBackground($rotation, rgba($blue, $a-light), rgba($red, $a-light));
    }

    &.vlight:before {
      @include linearGradientBackground($rotation, rgba($blue, $a-vlight), rgba($red, $a-vlight));
    }
  }

  &.bg-gradient-sky {
    &.reg:before {
      @include linearGradientBackground($rotation, rgba($darkblue, $a-reg), rgba($pastelblue, $a-reg));
    }

    &.light:before {
      @include linearGradientBackground($rotation, rgba($darkblue, $a-light), rgba($pastelblue, $a-light));
    }

    &.vlight:before {
      @include linearGradientBackground($rotation, rgba($darkblue, $a-vlight), rgba($pastelblue, $a-vlight));
    }
  }

  &.white-mask {
    &.reg:before {
      @include linearGradientBackground($rotation, rgba(255, 255, 255, $a-reg), rgba(255, 255, 255, $a-reg));
    }

    &.light:before {
      @include linearGradientBackground($rotation, rgba(255, 255, 255, $a-light), rgba(255, 255, 255, $a-light));
    }

    &.vlight:before {
      @include linearGradientBackground($rotation, rgba(255, 255, 255, $a-vlight), rgba(255, 255, 255, $a-vlight));
    }
  }

  &.black-to-white:before {
    @include linearGradientBackground(90deg, rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0.2));
  }

  &:before {
    @include beforeFullScreen();
  }
}


