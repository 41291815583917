$modal-padding: 30;

@mixin modalXL($width) {
  @media all and (min-width: $width) {
    .modal-xl {
      z-index: 999999;
      max-width: $width - $modal-padding !important;

      &.modal-xl-enforce {
        width: $width - $modal-padding !important;
      }
    }
  }
}


@include modalXL(600px);
@include modalXL(700px);
@include modalXL(800px);
@include modalXL(900px);
@include modalXL(1000px);
@include modalXL(1100px);
@include modalXL(1200px);
@include modalXL(1300px);
@include modalXL(1400px);
@include modalXL(1500px);
@include modalXL(1600px);
@include modalXL(1700px);
@include modalXL(1800px);
@include modalXL(1900px);
@include modalXL(2000px);
@include modalXL(2100px);
@include modalXL(2200px);
@include modalXL(2300px);
@include modalXL(2400px);
@include modalXL(2500px);
@include modalXL(2600px);
@include modalXL(2700px);
@include modalXL(2800px);
@include modalXL(2900px);
@include modalXL(3000px);
@include modalXL(3100px);
@include modalXL(3200px);
@include modalXL(3300px);
@include modalXL(3400px);
@include modalXL(3500px);
@include modalXL(3600px);
