@mixin dark-theme() {
  color: white;

  .theme-main-bg:before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    display: block;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgb(15, 15, 15)
  }

  .card {
    background: linear-gradient(90deg, rgb(30, 32, 34), rgb(43, 50, 54));
    box-shadow: none;
  }

  .theme-text-dark {
    color: #999999;
  }

  .theme-text-light {
    color: white;

  }

}
