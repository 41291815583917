.btm_settings-hover:hover {
  .btm_topleft_settings {
    visibility: visible;
    opacity: 1;
  }
}

.btm_topleft_settings {
  visibility: visible;
  position: absolute;
  top: 8px;
  right: 10px;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
  cursor: pointer;

  mat-form-field.mat-form-field-appearance-legacy {
    line-height: 1;
    font-size: 12px;
    padding: 0;

    .mat-form-field-wrapper {
      padding-bottom: 0.75rem;
      color: white;
      .mat-form-field-flex {
        .mat-form-field-infix {
          padding: 0.3rem 0;
          width: 80px;
        }
      }
      .mat-form-field-subscript-wrapper {
        margin: 0;
      }
    }
  }

}

.cdk-overlay-container {
  z-index: 9999;
}

#time-picker-wrapper.light #time-picker .time-picker-clock {
  .time-picker-clock-arrow, > button.active, .time-picker-clock-origin, .time-picker-clock-arrow span {
    background: #4285f4 !important;
  }
}

$dsr-green: rgb(76, 175, 80);
.dsr-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: $dsr-green !important;
}

.ptu-title, .comparator-title {
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 18px;
  }
}

mat-expansion-panel-header.disabled {
  color: #ddd;
}

.cm-stepper .mat-tab-label {
  flex: 1 1 auto !important;
  text-overflow: ellipsis;
  min-width: 50px;
  padding: 0 10px;
}

.cpc .mat-drawer-content.mat-sidenav-content {
  width: 100%;
}

.modal-nopadding {
  padding: 0;

    .mat-dialog-container {
      padding: 0 !important;
    }
}
.modal-overflow{
  overflow-y: auto;
}
