/* You can add global styles to this file, and also import other style files */
@import '../node_modules/hamburgers/_sass/hamburgers/hamburgers';
@import 'styles/mat-theme';
@import 'styles/transparent-theme.scss';
@import 'styles/gradients';
@import 'styles/card';
@import 'styles/forms';
@import 'styles/covers';
@import 'styles/variables';
@import 'styles/buttons.scss';
@import 'styles/scrollbar.scss';
@import 'styles/overrides.scss';
@import 'styles/themes/dark-wpd-theme';
@import 'styles/themes/light-wpd-theme';
@import 'styles/data-table';
@import "styles/modals";
@import '~@swimlane/ngx-datatable/release/index.css';
@import '~@swimlane/ngx-datatable/release/themes/material.css';
@import '~@swimlane/ngx-datatable/release/assets/icons.css';
@import '~@swimlane/ngx-datatable/release/index.css';
@import '~@swimlane/ngx-datatable/release/themes/material.css';
@import '~@swimlane/ngx-datatable/release/assets/icons.css';
@import "~ng-pick-datetime/assets/style/picker.min.css";

.wpd {
  &.dark-theme {
    @include dark-theme();
  }

  &.light-theme {
    @include light-theme();
  }
}

body {
  background-color: #eee;
}

button:focus {
  outline: none;
}

.invalid-feedback.dont-hide {
  display: inherit;
}

.text-lighter {
  font-weight: 200;
}

header li:focus {
  outline: none;
}

.navbar.scrolling-navbar {
  padding: 0;
}

.top-nav-collapse {
  background-color: rgba(0, 123, 255, 0.2);
}

@media only screen and (max-width: 768px) {
  .navbar {
    background-color: rgba(0, 123, 255, 0.6);
  }
}

.head-space {
  margin-top: $header-height * 1.3;
}

.mat-form-field.fw, mat-form-field.fw {
  width: 100%;
}

.app {
  flex: 1;
  display: flex;
}

html, body, app-root, cm-app-root, app-dashboard, cm-cpc-view, cm-cpcs-list, app-setpoint-graph {
  height: 100%;
  flex: 1 1 auto !important;
}

app-auth, app-landing, cm-cpc-view {
  flex: 1;
  display: flex;
  height: 100%;

}

.fullscreen {
  height: 100%;
  width: 100%;
}

mat-form-field.white-theme {
  input {
    color: white;
  }

  .mat-form-field-ripple {
    background-color: white;
  }

  .mat-form-field-underline {
    background-color: rgba(255, 255, 255, 0.9);
  }

  &:not(.mat-focused):not(.mat-form-field-invalid) {
    label, .mat-form-field-label, .mat-form-field-appearance-legacy .mat-form-field-label {
      color: rgba(255, 255, 255, 0.9) !important;
    }
  }
}

.congestionpoint {
  &.mat-option {
    font-size: 0.85rem !important;

  }

  &.mat-select {
    font-size: 0.62rem !important;
  }
}

.text-lighter-3{
  font-weight: 300;
}
