.card {

  &.card-cascade {

    width: 100%;

    &.card-body {
      padding-top: 1.8rem;
    }

    &.narrower {
      margin-top: 30px;

      .view {
        margin-left: 4%;
        margin-right: 4%;
        margin-top: -20px;
      }
    }

    .view {
      -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
      box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .12), 0 6px 20px 0 rgba(0, 0, 0, .12);

      border-radius: 4px;

      &.gradient-card-header {
        padding: 1rem 1rem;
        text-align: center;
      }

    }

  }
}

.card-body, .float-wrapper {
  .form-header.floating {
    color: #fff;
    text-align: center;
    margin-top: -50px;
    padding: 0.5rem 0.5rem;
    border-radius: 4px;

    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);

    &.flat {
      box-shadow: none;
    }

    h1, h2, h3, h4, h5 {
      margin-bottom: 0;
    }
  }
}

.float-wrapper{
  padding: 1.25rem;
  text-align: center;
  .form-header.floating {
    margin-bottom: 1rem;
    &.br{
      border-radius: .25rem;
    }
  }
}
.card-body{
  .form-header.floating{
    margin-bottom: 2rem;
  }
}
